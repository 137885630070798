@import url('https://fonts.googleapis.com/css?family=Gugi|Rokkitt|Special+Elite');
.App,body {
  /*text-align: center;*/
  font-size: 18px;
  font-family: 'Special Elite', cursive !important; 
  background-color: rgba(253,253,253,1);
}
p {
  font-family: 'Rokkitt', sans-serif !important;
}
a {
  color: rgba(160,20,20,.8);
  font-family: 'Special Elite', cursive !important;   
}
.archive-item:active {
    background-color: rgba(13,4,5,.9);
    color: white !important;
    padding: 2px;
}
img[src="undefined"] {
  display: none;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.header-wrap {
  position: relative;
  border-bottom: 1px solid rgba(13,4,5,.2);
}
.App-header {
  font-family: 'Special Elite', cursive !important;
  min-height: 4vh;
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: left;
  font-size: calc(1rem + 2vmin);
  color: #282c34;
  padding: 20px 9.1%;

}
.book-image {
    width: 50px;
    float: right;
    margin-right: 10px;
}
.letlink {
  padding: 3px;
}
.App-link {
  color: #61dafb;
}
.archive-item {
  font-size: 1.2rem;
  line-height: 1.6rem;
}
div#identity {
  position: relative;
  left: -54px;
}
span#wordmark {
  font-size: 2.5rem;
  width: 76px;
  color: rgba(13,4,5,.9) !important;
}
span#pepper-logo {
  font-size: 3.6rem;
  line-height: 3rem;
  float:left;
  margin-right: .5rem;
  color: rgba(160,20,20,.8);
}
div#tagline {
  font-size: 1rem;
  font-family: 'Special Elite', cursive !important;
  font-style: italic;
  color: rgba(40,40,40,.9);  
}
div#page-menu {
  border-top: 1px solid #121212;
  padding: .5rem 10%;
  max-width: 1080px;
}
div#page-menu ul {
  margin: 0;
  padding: 0;
}
div#page-menu a {
    font-size: 1.1rem;
    padding: 8px;
}
div#page-menu a:hover {
    background-color: rgba(13,4,5,.9);
    color: white;
}
div#body {
  padding-right: 3.25rem;
  padding-left: 3.25rem;
  padding: 2% 10%;
  max-width: 1080px;
}
h3.csum {
  width: 90%;
  margin: 0;
  height: 64px;
  line-height: 64px;
}
div.arrow-wrap {
  width: 3%;
  padding: .5rem;
  float: left;
  cursor: pointer;
}
div.char-title {
  clear: left;
  margin-top: 3rem;
  display: block;

}

.summary {
    padding: .5rem 0rem 1rem 0rem;
}
.summaries {
  margin-top: 1.6rem;
}
div#author {
    font-size: 1.6rem;
    font-weight: 500;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
